<i18n locale="th" lang="yaml" >
ticket_drone_detail.history : "History"
ticket_drone_detail.notes : "Notes"
ticket_drone_detail.lot_note : "บันทึกจาก Lot"
</i18n>

<template>
	<div class="ticket-drone">
		<a-tabs :default-active-key="defaultTabs" size="small" :tab-bar-gutter=0 class="ticket-drone-tabs">
			<a-tab-pane v-if="!isGeneralTicket" key="notes">
				<span slot="tab">
					<a-icon type="solution" />
					{{$t('ticket_drone_detail.notes')}}
				</span>
				<DroneLotNoteViewer
					class="ticket-lot-note"
					:hide-empty="true"
					:editable="false"
					:topic="$t('ticket_drone_detail.lot_note')"
					:lot-id="ticket.droneData.lot.id"
					:lot-name="ticket.droneData.lot.lotName"/>

				<DroneNoteList
					:can-add="canManageNote"
					:can-update="canManageNote"
					:drone="ticket.droneData.drone"/>
			</a-tab-pane>
			<a-tab-pane key="history">
				<span slot="tab">
					<a-icon type="history" />
					{{$t('ticket_drone_detail.history')}}
				</span>

				<TicketDroneHistory
					:ticket="ticket"/>
			</a-tab-pane>
		</a-tabs>
	</div>
</template>

<script>
import {Tabs} from "ant-design-vue"
import TicketDroneHistory from "./TicketDroneHistory.vue"
import DroneNoteList from "@components/drone/DroneNoteList.vue"
import DroneLotNoteViewer from "@components/drone/DroneLotNoteViewer.vue"
export default {
	components : {
		"a-tabs" : Tabs , "a-tab-pane" : Tabs.TabPane ,
		TicketDroneHistory , DroneNoteList , DroneLotNoteViewer
	} ,
	props : {
		ticket : {
			type : null,
			default : () => []
		} ,
	} ,
	computed : {
		canManageNote() {
			return this.$authorize('update', 'drone');
		} ,
		isGeneralTicket() {
			return !this.ticket.droneServiceDroneInstanceId
		} ,
		defaultTabs() {
			return this.isGeneralTicket ? "history" : "notes"
		}
	}
}
</script>

<style lang="less" scoped>
.ticket-drone {
	background : white;
	border : 1px solid @border-color-base;
	border-radius : @border-radius-base;

	&::v-deep {
		.ant-tabs-nav {
			font-family: @font-family-title;
			user-select : none;
		}
	}
}
.ticket-drone-tabs::v-deep {
	.ant-tabs-bar {
		margin-bottom : 0
	}
}
.ticket-lot-note {
	margin : 8px 24px;
}
</style>
